import '../vendor/materialize-sass/materialize.js';

window.addEventListener("load", () => {
  const element = document.querySelector("main");
  document.body.addEventListener("ajax:beforeSend", (event) => {
    document.querySelector(".backdrop2").style.display = 'block';
  });
  element.addEventListener("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    console.log('Ajax success');
    document.querySelector(".backdrop2").style.display = 'none';
  });
  element.addEventListener("ajax:error", () => {
    console.log('Ajax error');
    document.querySelector(".backdrop2").style.display = 'none';
  });
});
